import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../images/vitrinneLogo.png';
import './style.css';

export default function Dashboard() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [comerciantes, setComerciantes] = useState([]);
  const [quantidadeComerciantes, setQuantidadeComerciantes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [openMenuIndex, setOpenMenuIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedComerciante, setSelectedComerciante] = useState(null); 
  const navigate = useNavigate();

  const handleMenuToggle = (index) => {
    setOpenMenuIndex(openMenuIndex === index ? null : index);
  };

  const handleEdit = (comerciante) => {
    navigate('/editar', { state: { comerciante } });
  };

  const openDeleteModal = (comerciante) => {
    setSelectedComerciante(comerciante);
    setIsModalOpen(true);
  };

  const confirmDelete  = async () => {
    try {
      await api.delete(`comerciantes/deleteComerciante/${selectedComerciante.id}`);
      alert('Comerciante deletado com sucesso!');
      fetchComerciantes();
    } catch (err) {
        alert('Erro ao excluir!');
        console.log(err);
    } finally {
      setIsModalOpen(false);
      setSelectedComerciante(null);
    }
  };

  const cancelDelete = () => {
    setIsModalOpen(false);
    setSelectedComerciante(null);
  };

  const fetchComerciantes = async () => {
    try {
        const response = await api.get('/comerciantes/listComerciantes');
        const sortedComerciantes = response.data.sort((a, b) => {
            return a.nome.localeCompare(b.nome);
        });

        setComerciantes(sortedComerciantes);
        setQuantidadeComerciantes(sortedComerciantes.length);
    } catch (err) {
      console.error('Erro ao carregar comerciantes:', err);
    }
  };

  useEffect(() => {
    fetchComerciantes();
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const verificarStatusContrato = (validadeContrato) => {
    const hoje = new Date();
    const validade = new Date(validadeContrato);
    return validade < hoje ? 'Inativo' : 'Ativo';
  };

  const filteredComerciantes = comerciantes.filter((comerciante) =>
    comerciante.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
    comerciante.servico.toLowerCase().includes(searchTerm.toLowerCase()) ||
    comerciante.cidade.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredComerciantes.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredComerciantes.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(prevPage => prevPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(prevPage => prevPage - 1);
  };

  const formatarCpfCnpj = (cpfCnpj) => {
    const valorLimpo = cpfCnpj.replace(/\D/g, '');
  
    if (valorLimpo.length === 11) {
      return valorLimpo.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (valorLimpo.length === 14) {
      return valorLimpo.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }
  
    return cpfCnpj;
  }

  return (
    <div className='main'>
      <div className='header'>
        <img src={logo} alt='logo'/>
      </div>
      <div>
        <Link to="/login">
          <button className='logout-button'>
              <span className="material-symbols-outlined notranslate">&#xe9ba;</span>
          </button>
        </Link> 
      </div>
      <div className="dashboard-container">
        <>
          {isMobile ? (
            <div className='comerciantes-dashboard'>
              <div className='input-div-dashboard'>
                <span className="material-symbols-outlined notranslate">&#xe8b6;</span>
                <input type='text' 
                    className='search-bar' 
                    placeholder='Filtre os comerciantes por nome, cidade ou serviço' 
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)} />
              </div>
              <h1>Gerencie os comerciantes da plataforma</h1>
              {currentItems.map(comerciante => (
                <div
                  key={comerciante.id}
                  id={verificarStatusContrato(comerciante.contrato) === 'Inativo' ? 'inativo' : undefined}
                  className="card-container"
                  onClick={(e) => handleEdit(comerciante, e)} // Adiciona um clique no card para exibir o mini-menu
                  style={{ cursor: 'pointer' }} // Indicando que é interativo
                >
                  {verificarStatusContrato(comerciante.contrato) === 'Inativo' && (
                    <div className="status-inativo">
                      <span className="material-symbols-outlined notranslate">&#xe88e;</span>
                      <p>O contrato deste comerciante expirou</p>
                    </div>
                  )}
                  <div className='card'>
                    <div className='profile-image-div'>
                      <img className='profile-image' src={comerciante.profileImage} alt='profile-image'></img>
                    </div>
                    <div className='card-info'>
                      <div>
                        <h1>{comerciante.nome || 'Nome Desconhecido'}</h1>
                        <span id='destaque' className="material-symbols-outlined notranslate">
                          {comerciante.tipoContrato === 'normal' ? '' :
                          comerciante.tipoContrato === 'destaque' ? String.fromCharCode(0xe838) : ''}
                        </span>
                      </div>
                      <div>
                        <span className="material-symbols-outlined notranslate">
                          {comerciante.tipo === 'comércio' ? String.fromCharCode(0xe8d1) :
                          comerciante.tipo === 'prestador de serviço' ? String.fromCharCode(0xea3c) :
                          comerciante.tipo === 'imovel' ? String.fromCharCode(0xe73a) : String.fromCharCode(0xe8d1)}
                        </span>
                        <label>
                          {comerciante.tipo}
                        </label>
                      </div>
                      <h1>{comerciante.servico || 'Serviço Desconhecido'}</h1>
                    </div>
                  </div>
                </div>
              ))}
              <Link to={'/cadastro'}>
                <span id='cadastro-button-mobile' className="material-symbols-outlined notranslate">&#xe145;</span>
              </Link>
              <div className="pagination">
                <button onClick={handlePrevPage} disabled={currentPage === 1}>
                  Anterior
                </button>
                <span>Página {currentPage} de {totalPages}</span>
                <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                  Próxima
                </button>
              </div>
            </div>
          ) : (
            <div className="dashboard-table">
              <div className='dashboard-filter'>
                <p>
                  <span className="material-symbols-outlined notranslate">
                    &#xef3d;
                  </span> 
                  Painel do administrador
                </p>
                <div className='input-div-dashboard'>
                  <span className="material-symbols-outlined notranslate">&#xe8b6;</span>
                  <input type='text' 
                      className='search-bar' 
                      placeholder='Filtre os comerciantes por nome, cidade ou serviço' 
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)} />
                </div>
                <Link to='/cadastro'>
                  <button className='button-cadastrar'>Cadastrar comerciante</button>
                </Link>
              </div>
              <p>  
                {filteredComerciantes.length} {filteredComerciantes.length > 1 && filteredComerciantes.length < quantidadeComerciantes ? 'Resultados' : filteredComerciantes.length === quantidadeComerciantes ? 'Comerciantes cadastrados' : 'Resultado'}
              </p>
              <table>
                <thead>
                  <tr>
                    <th>Comerciante</th>
                    <th>Serviço</th>
                    <th>Cidade</th>
                    <th>Data de cadastro</th>
                    <th>Categoria</th>
                    <th>Validade do Contrato</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((comerciante) => (
                    <tr key={comerciante.id}>
                      <td>
                        <div className='td-div-comerciante'>
                          <img 
                            src={comerciante.profileImage || 'default-image.png'} 
                            alt={comerciante.nome} 
                            className="profile-image" 
                          />
                          <div>
                            <label className='notranslate'>
                              {comerciante.nome}
                            </label>
                            <p>{formatarCpfCnpj(comerciante.cpfCnpj)}</p>
                          </div>
                        </div>
                      </td>
                      <td>{comerciante.servico}</td>
                      <td>{comerciante.cidade}</td>
                      <td> 
                        <div className='td-tipo-div'>
                          <div>
                            {new Date(comerciante.createdAt).toLocaleDateString('pt-BR', { timeZone: 'UTC' })}
                          </div>
                        </div>
                      </td>
                      <td>{comerciante.tipo}</td>
                      <td>{new Date(comerciante.contrato).toLocaleDateString('pt-BR', { timeZone: 'UTC' })}</td>
                      <td>
                        <div  className={verificarStatusContrato(comerciante.contrato) === 'Inativo' ? 'inativo' : 'ativo'}>
                          <div>
                            <span className="material-symbols-outlined notranslate">
                              {verificarStatusContrato(comerciante.contrato) === 'Inativo' ? String.fromCharCode(0xe88e) : String.fromCharCode(0xe5ca)}
                            </span>
                            {verificarStatusContrato(comerciante.contrato)}
                          </div>
                        </div>
                      </td>
                      <div className="actions">
                      <button onClick={() => handleMenuToggle(comerciante.id)}>⋮</button>
                      {openMenuIndex === comerciante.id && (
                        <div className="mini-menu">
                          <button onClick={() => handleEdit(comerciante)}>
                            <span className="material-symbols-outlined notranslate" style={{ color: '#67179e' }}>&#xe3c9;</span>
                            Editar
                          </button>
                          <button onClick={() => openDeleteModal(comerciante)}>
                            <span className="material-symbols-outlined notranslate" style={{ color: '#E53935' }}>&#xe872;</span>
                            Excluir
                          </button>
                        </div>
                      )}
                    </div>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="pagination">
                <button onClick={handlePrevPage} disabled={currentPage === 1}>
                  Anterior
                </button>
                <span>Página {currentPage} de {totalPages}</span>
                <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                  Próxima
                </button>
              </div>
              {isModalOpen && (
                  <div className="modal-delete-overlay">
                      <div className="modal-delete-content">
                          <h2>Confirmação</h2>
                          <p>Tem certeza que deseja deletar este comerciante?</p>
                          <div>
                            <button onClick={confirmDelete} style={{ background: '#FFEBEE' }}>
                              <span className="material-symbols-outlined notranslate" style={{ color: '#E53935' }}>&#xe872;</span>
                              Excluir
                            </button>
                            <button onClick={cancelDelete}>Cancelar</button>
                          </div>
                      </div>
                  </div>
              )}
            </div>
          )}
        </>
      </div>
    </div>
    
  );
}
