import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import defaultImg from '../../images/defaultImg.png';
import categoriaImage from '../../images/categoriaLista.png';
import '../../global.css';
import './style.css';
import logoAlternativo from '../../images/vitrinneLogoAlternative.png';
import baseboardLogo from '../../images/splashScreenLogo.png';
import instagram from '../../images/instagramBaseboard.png';
import Whatsapp from '../../images/whatsappBaseboard.png';
import gmail from '../../images/Gmail.png';
import api from '../../services/api';
import ComercianteDetails from '../../componnents/ComercianteDetails';

export default function CategoriaLista() {
  const [isMobile] = useState(window.innerWidth <= 768);
  const { tipo } = useParams(); 
  const [servicos, setServicos] = useState([]);
  const [comerciantes, setComerciantes] = useState([]);
  const [comerciantesFiltrados, setComerciantesFiltrados] = useState([]);
  const [modalAberto, setModalAberto] = useState(true);
  const [servicoSelecionado, setServicoSelecionado] = useState(null);
  const location = useLocation();
  const [selectedComerciante, setSelectedComerciante] = useState(null);
  const [modalComercianteDetails , setModalComercianteDetails ] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const cidadeSelecionada = location.state?.cidadeSelecionada;

  const openModalComercianteDetails = (comerciante) => {
    setSelectedComerciante(comerciante);
    setModalComercianteDetails(true);
  }

  const closeModalComercianteDetails = () => {
    setSelectedComerciante(null);
    setModalComercianteDetails(false);
  }

  const fetchDados = useCallback(async () => {
    try {
      const response = await api.get('/comerciantes/listComerciantes');
      const comerciantesFiltrados = response.data.filter(comerciante => comerciante.cidade === cidadeSelecionada);
      const comerciantesData = comerciantesFiltrados.filter(comerciante => comerciante.tipo === tipo);
      const servicosUnicos = [...new Set(comerciantesData.map(comerciante => comerciante.servico))].sort((a, b) => a.localeCompare(b));
      setComerciantes(comerciantesData);
      setServicos(servicosUnicos);
      
      if (!servicoSelecionado && servicosUnicos.length > 0) {
        setServicoSelecionado(servicosUnicos[0]); 
      }
    } catch (err) {
      console.log(err);
    }
  }, [tipo, servicoSelecionado, cidadeSelecionada]);

  useEffect(() => {
    fetchDados();
    const interval = setInterval(fetchDados, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, [fetchDados]);

  useEffect(() => {
    if (servicoSelecionado) {
      const comerciantesFiltrados = comerciantes.filter(comerciante => comerciante.servico === servicoSelecionado);
      setComerciantesFiltrados(comerciantesFiltrados);
    }
  }, [servicoSelecionado, comerciantes]);

  const trocarServico = (servico) => {
    setServicoSelecionado(servico);
    setModalAberto(false); 
  };

  const abrirWhatsApp = (numero) => {
    const formattedNumber = numero.replace(/\D/g, '');
    const mensagem = encodeURIComponent("Olá, vim do Vitrinne.net e gostaria de saber como posso expor meu negócio lá também!");
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${'55'+formattedNumber}&text=${mensagem}`;
    window.open(whatsappUrl, '_blank'); 
  };


  const filteredComerciantes = comerciantesFiltrados.filter((comerciante) =>
    comerciante.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
    comerciante.servico.toLowerCase().includes(searchTerm.toLowerCase())  
  );

  return (
    <div className='main'>
      {isMobile ? (
        <>
          <div className='categoria-lista-main'>
              <Link to='/' state={{cidadeSelecionada}}>
                <span className="material-symbols-outlined notranslate">&#xe5c4;</span>
              </Link>
            
              <div className='div-title-categoria'>
                <span className="material-symbols-outlined notranslate">{
                  tipo === 'comércio' ? String.fromCharCode(0xe8d1) :
                  tipo === 'prestador de serviço' ? String.fromCharCode(0xea3c) :
                  tipo === 'imovel' ? String.fromCharCode(0xe73a) : String.fromCharCode(0xe8d1)
                }</span> 
                <p>{tipo === 'comércio' ? 'Comércios do tipo ' :
                  tipo === 'prestador de serviço' ? 'Prestadores de serviço do tipo ' :
                  tipo === 'imovel' ? 'Imóvel do tipo ' : ''} 
                  '{servicoSelecionado}'
                </p>
              </div>
              {modalAberto && (
                <div className='modal-tipo'>
                  <div className='modal-content-tipo'>
                    <button onClick={() => setModalAberto(false)} className='close-modal-cidade'>
                      <span className="material-symbols-outlined notranslate">&#xe5cd;</span> 
                    </button>
                    <h2>Escolha um tipo de {tipo} </h2>
                    <img src={categoriaImage} alt='categoriaImg' />
                    <ul>
                      {servicos.map((servico, index) => (
                        <li key={index} onClick={() => trocarServico(servico)}>
                          <div className='li-div-tipo'>
                            <span className="material-symbols-outlined notranslate">{
                              tipo === 'comércio' ? String.fromCharCode(0xe8d1) :
                              tipo === 'prestador de serviço' ? String.fromCharCode(0xea3c) :
                              tipo === 'imovel' ? String.fromCharCode(0xe73a) : String.fromCharCode(0xe8d1)
                            }</span>  
                            {servico}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            <div className='comerciantes'>
              {comerciantesFiltrados.length > 0 ? (
                comerciantesFiltrados.map((comerciante) => (
                  <Link to="/comerciantedetails" state={{ comerciante, cidadeSelecionada }} key={comerciante.id}>
                    <div className='card'>
                      <div className='profile-image-div'>
                        <img className='profile-image' src={comerciante.profileImage || defaultImg} alt='profile-image' />
                      </div>
                      <p className='notranslate'>{comerciante.nome || 'Nome Desconhecido'}</p>
                      <div>
                        <h1>{comerciante.servico || 'Serviço Desconhecido'}</h1>
                      </div>
                    </div>
                  </Link>
                ))
              ) : (
                <p>Ainda não há {tipo} cadastrado em {cidadeSelecionada}.</p>
              )}
            </div>
          </div>
      </>
      ) : (
        <>
        <div className='header-desktop'>
          <div className='content'>
            <Link to="/" state={{cidadeSelecionada}}>
              <img src={logoAlternativo} alt='logoalternativo'/>
            </Link>
            <div className='input-div'>
              <span className="material-symbols-outlined notranslate">&#xe8b6;</span>
              <input 
                type='text' 
                className='search-bar' 
                placeholder='Procurando por alguma loja ou serviço?' 
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)} 
              />
            </div>
            <Link to="/login">
              <button className='login-button-desktop'>
                Entrar
                <span className="material-symbols-outlined notranslate">&#xea77;</span>
              </button>
            </Link> 
          </div>
        </div>
        {modalAberto && (
                <div className='modal-tipo'>
                  <div className='modal-content-tipo'>
                    <button onClick={() => setModalAberto(false)} className='close-modal-cidade'>
                      <span className="material-symbols-outlined notranslate">&#xe5cd;</span> 
                    </button>
                    <h2>Escolha um tipo de {tipo} </h2>
                    <img src={categoriaImage} alt='categoriaImg' />
                    <ul>
                      {servicos.map((servico, index) => (
                        <li key={index} onClick={() => trocarServico(servico)}>
                          <div className='li-div-tipo'>
                            <span className="material-symbols-outlined notranslate">{
                              tipo === 'comércio' ? String.fromCharCode(0xe8d1) :
                              tipo === 'prestador de serviço' ? String.fromCharCode(0xea3c) :
                              tipo === 'imovel' ? String.fromCharCode(0xe73a) : String.fromCharCode(0xe8d1)
                            }</span>  
                            {servico}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
        <div className='catalog-desktop'>
          <div className='voltar'>
            <Link className='link' to='/' state={{cidadeSelecionada}}>
              <span className="material-symbols-outlined notranslate">&#xe5c4;</span>
            Voltar
            </Link>
          </div>
            <div className='comerciantes-title'>
              <div className='div-title-categoria-desktop'>
                <span className="material-symbols-outlined notranslate">{
                  tipo === 'comércio' ? String.fromCharCode(0xe8d1) :
                  tipo === 'prestador de serviço' ? String.fromCharCode(0xea3c) :
                  tipo === 'imovel' ? String.fromCharCode(0xe73a) : String.fromCharCode(0xe8d1)
                }</span> 
                <p>{tipo === 'comércio' ? 'Comércios do tipo ' :
                  tipo === 'prestador de serviço' ? 'Prestadores de serviço do tipo ' :
                  tipo === 'imovel' ? 'Imóvel do tipo ' : ''} 
                  '{servicoSelecionado}'
                </p>
              </div>
            </div>
            <div className='divider'></div>    
            <div className='destaques'>
            {filteredComerciantes.map(comerciante => (
              <div className='card-destaque' onClick={() => openModalComercianteDetails(comerciante)}>
                <div className='profile-image-div'>
                  <img className='profile-image' src={comerciante.profileImage || defaultImg} alt='profile-image'/>
                </div>
                <div className='destaque-infos'>
                  <p className='nome'>{comerciante.nome || 'Nome Desconhecido'}</p>
                  <div>
                    <span className="material-symbols-outlined notranslate">
                      {comerciante.tipo === 'comércio' ? String.fromCharCode(0xe8d1) :
                      comerciante.tipo === 'prestador de serviço' ? String.fromCharCode(0xea3c) :
                      comerciante.tipo === 'imovel' ? String.fromCharCode(0xe73a) : String.fromCharCode(0xe8d1)}
                    </span>
                    <p>{comerciante.tipo}</p>
                  </div>
                  <h1>{comerciante.servico || 'Serviço Desconhecido'}</h1>
                </div>
              </div>
            ))}
            </div>
        </div>
        {modalComercianteDetails && selectedComerciante && (
          <div className="modal-comercianteDetails" onClick={closeModalComercianteDetails}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <ComercianteDetails
                comerciante={selectedComerciante}
                cidadeSelecionada={cidadeSelecionada}
                onClose={closeModalComercianteDetails}
              />
            </div>
          </div>
        )}
        <div className='contato'>
          <p>Quer aparecer aqui no <p className='vitrinne'>Vitrinne</p>?</p>
          <p>Entre em contato conosco através das redes abaixo ou <p className='click-contato' onClick={() => abrirWhatsApp('88988867417')}>Clique aqui!</p></p>
        </div>

        <div className='baseboard-desktop'>
          <div className='left'>
            <img src={baseboardLogo} alt='logo'/>
          </div>
          <div className='right'>
            <div className='baseboard-socials'>
              <div>
                <img src={instagram} alt='instagram' />
                <a href='https://www.instagram.com/vitrinnenet/'>@vitrinnenet</a>
              </div>
              <div>
                <img src={Whatsapp} alt='whatsapp' />
                <p onClick={() => abrirWhatsApp('88988867417')}>+55 (88) 9 8886-7417</p>
              </div>
              <div>
                <img src={gmail} alt='gmail' />
                <p>vitrinne.nordeste@gmail.com</p>
              </div>
            </div>
          </div>
        </div>
        <div className='dev'>2024<span class="material-symbols-outlined notranslate">&#xe90c;</span>Dev. Jonas-Gomes</div>
        </>
      )}
    </div>
  );
}