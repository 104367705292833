import React, { useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Catalog from './pages/Catalog';
import NotFound from './pages/NotFound';
import ComercianteDetails from './pages/ComercianteDetails';
import Busca from './pages/Busca';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import CategoriaLista from './pages/CategoriaLista';
import Cadastro from './pages/Cadastro';
import PrivateRoute from './privateRoutes';
import LoadingSpinner from './componnents/Loading';
import Editar from './pages/Editar';
import Privacidade from './pages/Privacidade';


function AppRoutes() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  React.useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [location]);

  return (
    <>
      {loading && <LoadingSpinner />}
      <Routes>
        <Route path="/" element={<Catalog />} />
        <Route path="/comerciantedetails" element={<ComercianteDetails />} />
        <Route path="/busca" element={<Busca />} />
        <Route path="/login" element={<Login />} />
        <Route path="/categorias/:tipo" element={<CategoriaLista />} />
        <Route path="/privacidade" element={<Privacidade />} />
        <Route path="*" element={<NotFound />} />

        <Route path="/dashboard" 
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route path="/cadastro" 
          element={
            <PrivateRoute>
              <Cadastro />
            </PrivateRoute>
          }
        />
        <Route path="/editar" 
          element={
            <PrivateRoute>
              <Editar />
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
}

export default AppRoutes;
