import React from 'react';
import { useState, useEffect } from 'react';
import './style.css';
import '../../global.css'
import {Link, useNavigate} from 'react-router-dom';
import api from '../../services/api';
import InputMask from 'react-input-mask';
import ImageCropper from '../../componnents/ImageCropper';
import municipios from './municipiosBrasil.json';
import Loading from '../../componnents/Loading'; 

export default function Cadastro() {
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [nome, setNome] = useState('');
  const [cidade, setCidade] = useState('');
  const [contato, setContato] = useState('');
  const [instagram, setInstagram] = useState('');
  const [tipo, setTipo] = useState('');
  const [servico, setServico] = useState('');
  const [descricao, setDescricao] = useState('');
  const [contrato, setContrato] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [comercianteImages, setComercianteImages] = useState([]);
  const [cidadesFiltradas, setCidadesFiltradas] = useState([]);
  const [cidadeSelecionada, setCidadeSelecionada] = useState('');
  const [mask, setMask] = useState('99.999.999/9999-99');
  const [comercianteImagesPreview, setComercianteImagesPreview] = useState([]);
  const [croppedImage, setCroppedImage] = useState([]);
  const [isCropperOpen, setIsCropperOpen] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const openCropper = () => {
    setIsCropperOpen(true);
  };

  const handleComercianteImagesChange = (e) => {
    const files = Array.from(e.target.files); 
    setComercianteImages((prevImages) => [...prevImages, ...files]);
    const previewUrls = files.map((file) => URL.createObjectURL(file));
    setComercianteImagesPreview((prevPreviews) => [...prevPreviews, ...previewUrls]);
  };

  const removeImage = (index) => {
    setComercianteImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setComercianteImagesPreview((prevPreviews) => prevPreviews.filter((_, i) => i !== index));
  };

  const handleCrop = (file) => {
    setProfileImage(file);
    const imageUrl = URL.createObjectURL(file);
    setCroppedImage(imageUrl)
  };

  const handleClose = () => {
    setIsCropperOpen(false);
  };

  const handleCpfCnpjChange = (e) => {
    setCpfCnpj(e.target.value.replace(/\D/g, ''));
  };

  const apenasNumeros = (str) => str.replace(/\D/g, '');

  const handleBlur = () => {
      if (cpfCnpj.length === 11) {
          setMask('999.999.999-99');
      } else {
          setMask('99.999.999/9999-99');
      }
  };

  const handleBlurCity = () => {
    if (!cidadeSelecionada) {
      setCidade(''); // Limpa se nada foi selecionado
    }
    setCidadeSelecionada('');
  };

  const handleComercianteImageClick = () => {
    document.getElementById("file-comercianteImages").click();  // Abre o seletor de arquivos
  };

  const handleFocus = () => {
      setMask('99.999.999/9999-99');
  };

  const handleCidadeChange = (e) => {
    const inputValue = e.target.value;
    setCidade(inputValue);
    const filteredCities = municipios.cities.filter(city => 
        city.name.toLowerCase().includes(inputValue.toLowerCase())
    );
    setCidadesFiltradas(filteredCities);
  };

  const handleCidadeSelect = (selectedCity) => {
    const cidadeFormatada = `${selectedCity.name} - ${estados[selectedCity.state_id]}`;
    setCidade(cidadeFormatada);
    setCidadeSelecionada(cidadeFormatada);
    setCidadesFiltradas([]);
  };

  const servicosOpcoes = {
    'comércio': [
        'Açaiteria', 'Açougue', 'Acadêmia', 'Adesivos', 'Agropecuária', 'Alimentos', 'Animais', 'Armarinho', 
        'Ateliê', 'Auto escola', 'Autopeças', 'Bazar', 'Bicicletaria', 'Bijuterias', 'Bomboniere', 'Brechó', 
        'Brinquedos', 'Calçados', 'Capotarias', 'Casa de festas', 'Casa de material de construção', 
        'Casa de tecidos', 'Construção', 'Copiadoras', 'Cosméticos', 'Decoração', 'Distribuidora de bebidas', 
        'Doceria', 'Eletrodomésticos', 'Eletrônicos', 'Embalagens', 'Empréstimos', 'Energia solar', 
        'Enxoval para bebês', 'Equipadora', 'Esportes', 'Estacionamentos', 'Estamparias', 'Estética automotiva', 
        'Farmácia', 'Ferragens', 'Floricultura', 'Fotografia', 'Funilaria', 'Games', 'Gás e água', 'Gelateria', 
        'Gráfica', 'Hortifrúti', 'Hotel', 'Hotel para pets', 'Iluminação', 'Informática', 'Instrumentos musicais', 
        'Jardinagem', 'Joalheria', 'Lavanderia', 'Livraria', 'Loja de departamentos', 'Loja de fantasias', 
        'Loja de móveis', 'Madeireira', 'Manequins', 'Marcenaria', 'Material escolar', 'Mercado', 'Moda praia', 
        'Moto peças', 'Móveis', 'Móveis usados', 'Música', 'Nutrição e suplementos', 'Oficina de costura', 'Ótica', 
        'Papelaria', 'Perfumes', 'Pet shop', 'Pizzarias', 'Pneus', 'Pousada', 'Presentes', 'Queijaria', 
        'Rações', 'Relojoaria', 'Restaurantes', 'Rouparia', 'Roupas', 'Roupas de cama, mesa e banho', 
        'Salão de beleza', 'Sapateria', 'Sex shop', 'Sorveteria', 'Suplementos', 'Suplementos alimentares', 
        'Tabacaria', 'Tatuagem e piercing', 'Tecidos', 'Tintas', 'Turismo', 'Uniformes', 'Utilidades domésticas', 
        'Variedades', 'Venda de automóveis', 'Venda de motos', 'Vidraçaria'
    ],
    'prestador de serviço': [
        'Advogado', 'Agente de viagens', 'Arquiteto', 'Assistência técnica', 'Aviamentos', 'Babá', 'Barbeiro', 
        'Borracheiro', 'Buffet para eventos', 'Cabeleireiro', 'Carpinteiro', 'Chaveiro', 'Conserto de celular', 
        'Contador', 'Cuidador de idosos', 'Dedetizador', 'Dentista', 'Designer de sobrancelhas', 
        'Designer gráfico', 'Despachante', 'Diarista', 'DJ', 'Eletricista automotivo', 
        'Eletricista residencial', 'Encanador', 'Entregador', 'Esteticista', 'Faxineira', 'Fisioterapeuta', 
        'Fotógrafo', 'Funileiro', 'Gestor de redes sociais', 'Guia turístico', 'Hidráulico', 
        'Hospedagem de sites', 'Instrutor de yoga', 'Jardineiro', 'Jornalista freelance', 'Lavador de carros', 
        'Limpeza de estofados', 'Limpeza de piscina', 'Locação de caminhões', 'Locação de máquinas', 
        'Locação de veículos', 'Locutor', 'Manicure e pedicure', 'Mecânica de carros', 'Mecânica de motos', 
        'Mecânica diesel', 'Mecânico', 'Montador de móveis', 'Moto táxi', 'Motorista particular', 
        'Nutricionista', 'Organização de festas', 'Ortopedista', 'Pedreiro', 'Personal trainer', 
        'Pintor', 'Professor particular', 'Psicólogo', 'Quiropraxista', 'Reboque', 
        'Refrigeração e ar-condicionado', 'Segurança', 'Serralheiro', 'Técnico de informática', 
        'Táxi', 'Terapeuta', 'Transportadora', 'Veterinário', 'Vidraceiro', 'Vistoria veicular', 'Zelador'
        ],
      imóvel: ['Construtora', 'Imobiliária', 'Lote']
  };

  const handleTipoChange = (e) => {
      setTipo(e.target.value);
      setServico('');
  };

  const estados = municipios.states;

  async function cadastrarComerciante(e) {
    e.preventDefault();
    
    setLoading(true);
    const formData = new FormData();

    formData.append('cpfCnpj', apenasNumeros(cpfCnpj));
    formData.append('nome', nome);
    formData.append('cidade', cidade);
    formData.append('contato', apenasNumeros(contato));
    formData.append('instagram', instagram);
    formData.append('tipo', tipo);
    formData.append('servico', servico);
    formData.append('descricao', descricao);
    formData.append('contrato', contrato);
    

    if (profileImage) {
        formData.append('profileImage', profileImage);
    }

    comercianteImages.forEach((image) => {
      formData.append('comercianteImages', image);
    });

    try {
        await api.post('comerciantes/createComerciante', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        
        alert('Cadastrado com sucesso!');
        navigate('/dashboard')
    } catch (err) {
        alert('Erro ao cadastrar!');
        console.log(err);
    } finally {
        setLoading(false); // Desativa o carregamento
    }
  
}

useEffect(() => {
  return () => {
    comercianteImagesPreview.forEach((url) => URL.revokeObjectURL(url));
  };
}, [comercianteImagesPreview]);


  return (
    <div className="main" id='cadastro-main'>
        {loading && <Loading />}
        <div className='cadastro-main'>
            <Link className='close' to={'/dashboard'}>
                <span className="material-symbols-outlined notranslate">&#xe5cd;</span>
            </Link>
            <div className='cadastro'>
                <form onSubmit={ cadastrarComerciante }>
                    <h1>Cadastrar comerciante</h1>
                    <div className='cadastro-form'>
                    <div className='label-input'>
                            <label>Tipo</label>
                            <div className='input-div'>
                                <span class="material-symbols-outlined notranslate">{tipo === 'comércio' ? String.fromCharCode(0xe8d1) : tipo === 'prestador de serviço' ? String.fromCharCode(0xea3c) : tipo === 'imóvel' ? String.fromCharCode(0xe73a) : ''}</span>
                                <select value={tipo} onChange={handleTipoChange} required>
                                    <option value="" disabled>Selecione o tipo de comerciante</option>
                                    <option value="comércio">Comércio</option>
                                    <option value="prestador de serviço">Prestador de Serviço</option>
                                    <option value="imóvel">Imóvel</option>
                                </select>
                                <span class="material-symbols-outlined notranslate">&#xe313;</span>
                            </div>
                        </div>
                        <div className='label-input'>
                            <label>Serviço</label>
                            <div className='input-div'>
                                <select value={servico} onChange={(e) => setServico(e.target.value)} required>
                                    <option value="" disabled>Selecione o serviço</option>
                                    {servicosOpcoes[tipo]?.map((opcao, index) => (
                                    <option key={index} value={opcao}>{opcao}</option>
                                    ))}
                                </select>
                                <span class="material-symbols-outlined notranslate">&#xe313;</span>
                            </div>
                        </div>
                        <div className='label-input'>
                            <label>CPF/CNPJ</label>
                            <div className='input-div'>
                                <span class="material-symbols-outlined notranslate">&#xe873;</span>
                                <InputMask
                                    mask={mask}
                                    maskChar={null}
                                    value={cpfCnpj}
                                    onChange={handleCpfCnpjChange}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    placeholder="Digite o CPF ou CNPJ"
                                    required
                                >
                                    {(inputProps) => (
                                        <input
                                        {...inputProps}
                                        type="text"
                                        />
                                    )}
                                </InputMask>
                            </div>
                        </div>
                        <div className='label-input'>
                            <label>Nome</label>
                            <div className='input-div'>
                                <span class="material-symbols-outlined notranslate">&#xe7fd;</span>
                                <input
                                    type="text"
                                    value={nome}
                                    onChange={(e) => setNome(e.target.value)}
                                    placeholder="Nome do Comerciante"
                                    required
                                />
                            </div>
                        </div>
                        <div className='label-input'>
                            <label>Cidade</label>
                            <div className='input-div'>
                                <span class="material-symbols-outlined notranslate">&#xe0c8;</span>
                                <input
                                    type="text"
                                    value={cidade}
                                    onChange={handleCidadeChange}
                                    onBlur={handleBlurCity}
                                    placeholder="Digite a cidade"
                                    required
                                />
                                </div>
                                {cidadesFiltradas.length > 0 && (
                                    <ul className="cidades-list">
                                        {cidadesFiltradas.map(city => (
                                            <li key={city.id} onClick={() => handleCidadeSelect(city)}>
                                                {city.name} - {estados[city.state_id]}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                        </div>    
                        <div className='label-input'>
                            <label>Contato</label>
                            <div className='input-div'>
                                <span class="material-symbols-outlined notranslate">&#xe0b0;</span>
                                <InputMask required value={contato} mask="(99) 9 9999-9999" onChange={e => setContato(e.target.value)} placeholder="Digite o telefone"/>
                            </div>
                        </div>
                        <div className='label-input' id='descricao'>
                            <label>Descrição</label>
                            <div className='input-div'>
                                <textarea
                                    value={descricao}
                                    onChange={(e) => setDescricao(e.target.value)}
                                    placeholder="Descrição das habilidades e serviços oferecidos pelo comerciante"
                                />
                            </div>
                        </div>
                        <div className='label-input'>
                            <label>Instagram</label>
                            <div className='input-div'>
                                <span class="material-symbols-outlined notranslate">&#xe0e6;</span>
                                <input
                                    type="text"
                                    value={instagram}
                                    onChange={(e) => setInstagram(e.target.value)}
                                    placeholder="@ do instagram"
                                />
                            </div>
                        </div>
                        <div className='label-input'>
                            <label>Data de Validade do Contrato</label>
                            <div className='input-div'>
                                <input
                                    type="date"
                                    value={contrato}
                                    onChange={(e) => setContrato(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='label-input'>
                            <label>Foto de Perfil</label>
                            <div className='input-div'>
                                <div className='images-div'>
                                  {profileImage  && (
                                    <img className="cropped-image-preview" src={croppedImage} alt="Imagem recortada" />
                                  )}
                                  <input type="button" onClick={openCropper} className='add-img-button' value="Selecionar e cortar imagem" required />
                                </div>

                                <ImageCropper
                                  isOpen={isCropperOpen}
                                  onClose={handleClose}
                                  onCrop={handleCrop}
                                />
                            </div>
                        </div>
                        <div className='label-input' id='comercianteImages'>
                            <label>Imagens do Comerciante (até 4)</label>
                            <div className='input-div'>
                                <div className="comercianteImages-div">
                                  {comercianteImagesPreview.map((imageUrl, index) => (
                                    <div key={index} className="image-preview-container">
                                      <img className="cropped-image-preview" src={imageUrl} alt={`Imagem ${index + 1}`} />
                                      <button required type="button" className='add-img-button' style={{ marginLeft:"5%", cursor: "pointer" }} onClick={() => removeImage(index)}>Remover</button>
                                    </div>
                                  ))}
                                  {comercianteImagesPreview.length < 4 && (
                                    <div>
                                      <input type="file" accept="image/*" multiple id='file-comercianteImages' onChange={handleComercianteImagesChange} style={{ display: "none" }} required />
                                      <button type="button" className='add-img-button' onClick={handleComercianteImageClick} style={{ marginLeft:"5%", cursor: "pointer" }}>
                                          Adicionar imagem
                                      </button>
                                    </div>
                                  )}
                                </div>
                            </div>
                        </div>
                        
                        <button className='acess-button' type="submit">Cadastrar</button>
                    </div>
                </form>
            </div>  
        </div>
    </div>
  ); 
}
