import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import api from '../../services/api';
import defaultImg from '../../images/defaultImg.png';
import '../../global.css';
import './style.css';

export default function Busca() {
  const [comerciantes, setComerciantes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const location = useLocation();
  const cidadeSelecionada = location.state?.cidadeSelecionada;
  
  const fetchComerciantes = useCallback(async () => {
    try {
      const response = await api.get('/comerciantes/listComerciantes');
      const comerciantesAtivos = response.data.filter(comerciante => {
        const dataContrato = new Date(comerciante.contrato);
        return comerciante.cidade === cidadeSelecionada && dataContrato > Date.now();
      });
      setComerciantes(comerciantesAtivos);
    } catch (err) {
      console.log(err);
    }
  }, [cidadeSelecionada]);

  useEffect(() => {
    fetchComerciantes();
    const interval = setInterval(fetchComerciantes, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, [fetchComerciantes]);


  const filteredComerciantes = comerciantes.filter((comerciante) =>
    comerciante.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
    comerciante.servico.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className='main'>
      <div className='busca'>
        <Link to='/' state={{cidadeSelecionada}}>
          <span className="material-symbols-outlined notranslate">&#xe5c4;</span>
        </Link>
        <div className='input-div'>
          <span className="material-symbols-outlined notranslate">&#xe8b6;</span>
          <input type='text' 
              className='search-bar' 
              placeholder='Pesquise comerciantes por nome ou serviço' 
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)} />
        </div>
        <label style={{ fontSize: '17px' }} >Buscando por: "{searchTerm}" em {cidadeSelecionada}</label>

          
        <div className='comerciantes'>
            {filteredComerciantes.map((comerciante) => (
            <Link to="/comerciantedetails" state={{ comerciante, cidadeSelecionada }} key={comerciante.id}>
            <div className='card'>
              <img className='profile-image' src={comerciante.profileImage || defaultImg} alt='profile-image'></img>
              <p className='notranslate'>{comerciante.nome || 'Nome Desconhecido'}</p>
              <div>
                  <h1>{comerciante.servico || 'Serviço Desconhecido'}</h1>
              </div>
            </div>
            </Link>
              ))}
          </div>
      </div>
      <div className='baseboard'>
          <button>
          <Link to="/">
              <span className="material-symbols-outlined notranslate">&#xe88a;</span>
              Início
          </Link> 
          </button>
          <button id='selected'>
          <Link to="/busca">
              <span className="material-symbols-outlined notranslate">&#xe8b6;</span>
              Busca
          </Link> 
          </button>
      </div>
    </div>
  );
}
