import React, { useRef, useState } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import './style.css';

const ImageUpload = ({ isOpen, onClose, onCrop }) => {
    const [image, setImage] = useState(null);
    const cropperRef = useRef(null);
    const [fileName] = useState("Selecionar arquivo");

    const handleButtonClick = () => {
        document.getElementById("file-input").click();  // Abre o seletor de arquivos
      };

      const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImage(URL.createObjectURL(file));
        }
    };

    const cropImage = () => {
        const cropper = cropperRef.current?.cropper;
        if (cropper) {
            cropper.getCroppedCanvas().toBlob((blob) => {
                if (blob) {
                    
                    const file = new File([blob], 'cropped-image.jpg', { type: 'image/jpeg' });
                
                    onCrop(file);
                    onClose();
                }
            });
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal">
            <div className="modal-content">
                <span className="material-symbols-outlined notranslate" id="close" onClick={onClose}>close</span>
                <div className='input-button-cropper'>
                    <input
                        type="file"
                        accept="image/*"
                        id="file-input"
                        onChange={handleFileChange}
                        style={{ display: "none" }} // Esconde o input original
                    />
                    <button type="button" onClick={handleButtonClick} style={{ cursor: "pointer" }}>
                        {fileName}
                    </button>
                </div>
                {image && (
                    <Cropper
                        src={image}
                        style={{ height: 200, width: '100%' }}
                        ref={cropperRef}
                        aspectRatio={1}
                        guides={false}
                    />
                )}
                <button className='crop-button' onClick={cropImage}><span className="material-symbols-outlined notranslate">content_cut</span>Cortar Imagem</button>
            </div>
        </div>
    );
};

export default ImageUpload;
