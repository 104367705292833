import React, { useState } from 'react';
import '../../global.css';
import './style.css';
import Whatsapp from '../../images/whatsapp.png';
import Instagram from '../../images/instagram.png';

export default function ComercianteDetails({ comerciante, cidadeSelecionada, onClose }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalProfileImageOpen, setModalProfileImageOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  if (!comerciante) {
    return <div>Nenhum comerciante foi selecionado</div>;
  }

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber; 
  };

  const copiarNumero = (numero) => {
    navigator.clipboard.writeText(numero);
    alert('Número copiado para a área de transferência!');
  };

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setModalOpen(true);
  };
  
  const closeModal = () => {
    setModalOpen(false);
  };

  const openModalProfileImage = () => {
    setModalProfileImageOpen(true);
  };
  
  const closeModalProfileImage = () => {
    setModalProfileImageOpen(false);
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % comerciante.comercianteImages.length);
  };
  
  const handlePrevious = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + comerciante.comercianteImages.length) % comerciante.comercianteImages.length);
  };

  const abrirWhatsApp = (numero) => {
    const formattedNumber = numero.replace(/\D/g, '');
    const mensagem = encodeURIComponent("Olá, vim do Vitrinne.net e gostaria de saber mais sobre seus serviços");
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${'55' + formattedNumber}&text=${mensagem}`;
    window.open(whatsappUrl, '_blank'); 
  };

  const abrirInstagram = (instagram) => {
    const instagramUrl = `https://www.instagram.com/${instagram}`;
    window.open(instagramUrl, '_blank'); 
  };

  return (
    <div className='main'>
      <div className='comerciante-details-desktop'>
        <div className='title'>
          <h1>Perfil do comerciante</h1>
          <button onClick={onClose} className="close-button">
            <span className="material-symbols-outlined notranslate">&#xe5cd;</span>
          </button>
        </div>
        <div className='profile-image-block'>
          <img 
            onClick={openModalProfileImage} 
            src={comerciante.profileImage} 
            alt={comerciante.nome} 
          />
          <div className='main-info'>
            <div className='tipo-contrato'>
              <label>
                {comerciante.tipo + ' '}
              </label>
            </div>           
            <label>{comerciante.nome}</label>
            <label className='servico'>{comerciante.servico}</label>
          </div>
        </div>
        <div className='content'>
          <div>
            <div>
              <span className="material-symbols-outlined notranslate">&#xe61d;</span>
              <label>TELEFONE</label>
            </div>
            <div className='numero'>
              <label>{formatPhoneNumber(comerciante.contato)}</label>
              <button onClick={() => copiarNumero(comerciante.contato)} className="copy-button">
                <span className="material-symbols-outlined notranslate">&#xe14d;</span>
              </button>
            </div>
          </div>
          {comerciante.instagram && (
            <div>
              <div>
                <span className="material-symbols-outlined notranslate">&#xe412;</span>
                <label>INSTAGRAM</label>
              </div>
              <label>@{comerciante.instagram}</label>
            </div>
          )}
          <div>
            <div>
              <span className="material-symbols-outlined notranslate">&#xe873;</span>
              <label>DESCRIÇÃO</label>
            </div>
            <label className='descricao'>{comerciante.descricao}</label>
          </div>
          <div>
            <div>
              <span className="material-symbols-outlined notranslate">&#xe412;</span>
              <label>MOSTRUÁRIO</label>
            </div>
            <div className="comerciante-images">
              {comerciante.comercianteImages && comerciante.comercianteImages.length > 0 ? (
                comerciante.comercianteImages.map((imageUrl, index) => (
                  <img 
                    key={index} 
                    src={imageUrl} 
                    alt={`Imagem ${index + 1} do comerciante`} 
                    className="image-thumbnail" 
                    onClick={() => openModal(index)}
                  />
                ))
              ) : (
                <p>Sem imagens disponíveis</p>
              )}
            </div>
          </div>
          <div className='socials-desktop'>
            <div onClick={() => abrirWhatsApp(comerciante.contato)} className='whatsapp'>
              <img alt='whatsapp' src={Whatsapp}/>
              Entre em contato pelo whatsapp
            </div>
            {comerciante.instagram && (
              <div onClick={() => abrirInstagram(comerciante.instagram)} className='instagram'>
                <img alt='instagram' src={Instagram}/>
                Entre em contato pelo instagram
              </div>
            )}
          </div>
        </div>
        {modalOpen && (
          <div className="modal-overlay" onClick={closeModal}>
            <div className="modal-content-images" onClick={(e) => e.stopPropagation()}>
              <img 
                src={comerciante.comercianteImages[currentImageIndex]} 
                alt={`Imagem ${currentImageIndex + 1} do comerciante`} 
                className="modal-image" 
              />
              <button onClick={closeModal} className="close-button">
                <span className="material-symbols-outlined notranslate">&#xe5cd;</span>
              </button>
              {comerciante.comercianteImages.length > 1 && (
                <>
                  <button onClick={handlePrevious} className="carousel-button previous-button">
                    <span className="material-symbols-outlined notranslate">&#xe5e0;</span>
                  </button>
                  <button onClick={handleNext} className="carousel-button next-button">
                    <span className="material-symbols-outlined notranslate">&#xe5e1;</span>
                  </button>
                </>
              )}
            </div>
          </div>
        )}
        {modalProfileImageOpen && (
          <div className="modal-overlay" onClick={closeModalProfileImage}>
            <div className="modal-content-images" onClick={(e) => e.stopPropagation()}>
              <img 
                src={comerciante.profileImage} 
                alt="Imagem de perfil do comerciante" 
                className="modal-image" 
              />
              <button onClick={closeModalProfileImage} className="close-button">
                <span className="material-symbols-outlined notranslate">&#xe5cd;</span>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
