import React from 'react';
import './style.css';
import '../../global.css';

export default function Privacidade() {

  return (
    <div className="main" id='login-main'>
        <div className='login-main'>
            <div className='login'>
                <h1>Política de Privacidade - Aplicativo Vitrinne</h1>
                <p><strong>Última atualização:</strong> 12/12/2024</p>
                <section>
                    <h2>1. Coleta de Dados</h2>
                    <p>Nosso aplicativo não coleta nem armazena informações pessoais dos usuários finais. Eles acessam um catálogo de estabelecimentos comerciais, prestadores de serviço e imóveis, sem necessidade de criar contas ou fornecer dados pessoais.</p>
                </section>

                <section>
                    <h2>2. Informações Exibidas</h2>
                    <p>O aplicativo exibe informações públicas fornecidas pelos comerciantes que entram em contato diretamente conosco fora da plataforma. As informações exibidas incluem:</p>
                    <ul>
                        <li>Nome do comerciante ou empresa</li>
                        <li>Instagram (quando aplicável)</li>
                        <li>Número de WhatsApp para contato</li>
                        <li>Foto de perfil do comerciante</li>
                        <li>Até 4 fotos de mostruário dos produtos ou serviços</li>
                    </ul>
                </section>

                <section>
                    <h2>3. Uso das Informações</h2>
                    <p>As informações exibidas são utilizadas exclusivamente para fins de divulgação no aplicativo. Os usuários finais podem visualizar esses dados para conhecer melhor os estabelecimentos cadastrados e entrar em contato diretamente com eles.</p>
                </section>

                <section>
                    <h2>4. Compartilhamento de Dados</h2>
                    <p>O Vitrinne não compartilha, vende ou aluga dados dos comerciantes a terceiros. Os contatos apresentados são fornecidos voluntariamente pelos próprios comerciantes para fins de divulgação pública.</p>
                </section>

                <section>
                    <h2>5. Segurança das Informações</h2>
                    <p>Empregamos medidas de segurança apropriadas para proteger as informações exibidas no aplicativo. No entanto, nenhum sistema de transmissão de dados é totalmente seguro, e não podemos garantir proteção absoluta.</p>
                </section>

                <section>
                    <h2>6. Direitos dos Comerciantes</h2>
                    <p>Caso um comerciante deseje atualizar, corrigir ou remover suas informações do aplicativo, basta entrar em contato conosco por meio de nossos canais oficiais.</p>
                </section>

                <section>
                    <h2>7. Alterações nesta Política</h2>
                    <p>Esta Política de Privacidade pode ser atualizada periodicamente. Recomendamos que os comerciantes e usuários finais revisem esta seção regularmente para se manterem informados sobre possíveis mudanças.</p>
                </section>

                <section>
                    <h2>8. Contato</h2>
                    <p>Se você tiver dúvidas sobre esta Política de Privacidade ou sobre como suas informações são exibidas no aplicativo, entre em contato através de nosso site oficial vitrinne.net.</p>
                </section>
                <p>&copy; 2024 Vitrinne. Todos os direitos reservados.</p>
            </div>  
        </div>
    </div>
  ); 
}
